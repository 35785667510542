import v from '@/main'

// 把时间变成年月日小时
function fromatTimestamp(ts){
  let date = new Date(Date.parse(ts));
  let Y = date.getFullYear() + '-';
  let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
  let D = date.getDate();
  let h = date.getHours() + ':';
  let m = date.getMinutes() ;
  // s = date.getSeconds(); 
  return Y+M+D+' '+h+m
  // console.log(Y+M+D+h+m+s); 
}


function fromatTimestamps(ts){
  let date = new Date(Date.parse(ts));
  let Y = date.getFullYear() + '-';
  let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
  let D = date.getDate();
  let h = date.getHours() + ':';
  let m = (date.getMinutes() == '0' ? '00' : date.getMinutes() )+ ':' ;
  let s = (date.getSeconds() == '0' ? '00' : date.getSeconds() ); 
  return Y+M+D+' '+h+m+s
  // console.log(Y+M+D+h+m+s); 
}

function formatDate(date) {  
  var y = date.getFullYear();  
  var m = date.getMonth() + 1;  
  m = m < 10 ? '0' + m : m;  
  var d = date.getDate();  
  d = d < 10 ? ('0' + d) : d;  
  return y + '-' + m + '-' + d;  
}
// 计算时间段
function formatDateL(data1,date2) {  
  // console.log(data1,date2)
  var len =  (Date.parse(new Date(date2)) - Date.parse(new Date(data1)))/1000;
  var y = Math.floor(len/3600);  
  var m = len%3600  
  var d = Math.floor(m/60);  
  return y + '小时' + d + '分钟' ;  
}

function setCookie(cname, cvalue, exmins) {
  let d = new Date();
  d.setTime(d.getTime() + (exmins*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname){
  let strcookie = document.cookie;
  let arrcookie = strcookie.split("; ");

  for ( let i = 0; i < arrcookie.length; i++) {
    let arr = arrcookie[i].split("=");
    if (arr[0] == cname){
      return arr[1];
    }
  }
  return "";
}

function delCookie(cname) {     
  setCookie(cname, "", -1); 
}

function getQueryString( name ){
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substr(1).match(reg)
  if (r != null) {
      return decodeURIComponent(r[2])
  }
  return null
}

// function share(){
//   console.log('成功')
//   v.WeixinJSBridge.invoke('sendAppMessage',{  
//     "appid": appid,  
//     "link": window.location.href,  
//     "desc": "descContent",  
//     "title": "shareTitle"  
//     }, function(res) {  
//       alert(res);  
//     })
// }
// 微信分享
function getShareConfig(shareContent,v){
  v.axios({
    method: 'GET',
    url:`/wechat/js-sdk?from=${v.base64.encode(window.location.href)}`,
    headers: {
      'Authorization': `Bearer ${getCookie('tokenDkhNww')}` 
    },
  }).then( (res) => {
    if(res.data.code == 0){
      wxset(res.data.data.config ,shareContent)
    }else{
      console.log(res.data.message,'=====')
    }
  })
}
function wxset(config, shareContent){
  v.wx.config(config);
  v.wx.ready( () => { 
    v.wx.onMenuShareAppMessage({ 
      title: shareContent.title, 
      desc: shareContent.desc,
      link: location.href.split('?')[0], //
      imgUrl: nowurl+'/share-logo.png', 
      success() {
        // 设置成功
      }
    }); 
    v.wx.onMenuShareTimeline({ 
      title: shareContent.title,
      desc: shareContent.desc, 
      link: location.href.split('?')[0],
      imgUrl: nowurl+'/share-logo.png', 
      success() {
        // 设置成功
      }
    });
  })
}
function throttle(fn,wait){
  var timer = null;
  return function(){
    var context = this;
    var args = arguments;
    if(!timer){
        timer = setTimeout(function(){
            fn.apply(context,args);
            timer = null;
        },wait)
    }
  }
}
// 去除中间空格
function clearWhite(time){
  if(time.split(' ')[1]){
    return time.split(' ')[0] + time.split(' ')[1]
  }else{
    return time
  }
}

function formatTimes(timelength){
  // var timestampLogin = new Date(timelength.replace(/-/g, '/')).getTime()-1000 ;
  var timestampLogin = new Date(Date.parse(timelength));
  var seconds = (Date.parse(new Date())- timestampLogin)/1000 ;
  var hour = Math.floor(seconds/3600);
  var day =  Math.floor(hour/24)
  var week = Math.floor(day/7)
  if(week>0){
    return week+'周前'
  }
  if(day>0){
    return day+'天前';
  }
  if(hour>0){
    return hour+'小时前';
  }
  var minute = Math.floor(seconds/60);
  console.log(minute)
  if(minute>0){
      return minute+'分钟前';
  }
  return seconds+'秒前';
}

function changeImgBg(img){
  if(img.indexOf('http') == -1){
    console.log(1)
    return {backgroundImage:'url(https://apiv2.baoxianguancha.com/storage/uploads/'+img+')'}
  }else{
    return {backgroundImage:'url('+img+')'}
  }
}
function changeImg(img){
  if(img.indexOf('http') != -1){
    return img
  }else{
    return 'https://apiv2.baoxianguancha.com/storage/uploads/'+img
  }
}

function changeImgCom(img){
  if(img){
    if(img.indexOf('http') != -1){
      return img
    }else{
      return 'https://apiv2.baoxianguancha.com/storage/uploads/'+img
    }
  }else{
    return img
  }
}

function closeWindowNow(){
  v.wx.closeWindow()
}
// var nowurl = 'https://dkh-vip-v2.wptomo.top' //跳转域名测试
// var domain = 'https://dkh-api.wptomo.top/api'   //接口域名测试
// var appid = 'wxdd1aae652aa93507'   //测试


// var nowurl = 'http://dkh-tmp.wptomo.top' //跳转域名临时正式
// var domain = 'https://dkh-api-tmp.wptomo.top/api'   //接口域名临时正式

var nowurl = 'https://hui.baoxianguancha.com' //跳转域名正式
var domain = 'https://apiv2.baoxianguancha.com/api'   //接口域名正式
var appid = 'wx0a1c290018a3129d'   //正式


// var appid = 'wx42196a99c8d26acb'   //旧版正式



export default {
  // share,
  changeImg,
  changeImgBg,
  changeImgCom,
  fromatTimestamp,
  fromatTimestamps,
  formatDateL,
  formatDate,
  setCookie,
  getCookie,
  delCookie,
  getQueryString,
  getShareConfig,
  nowurl,
  appid,
  domain,
  throttle,
  closeWindowNow,
  formatTimes,
  clearWhite
}