import Vue from 'vue'
import Router from 'vue-router'
import 'vant/lib/index.css';
import 'vant/lib/index.less';
// import storage from 'good-storage'
import Global from '@/assets/js/global'


// import { Uploader } from 'vant';
// Vue.use(Uploader);
// import { Empty } from 'vant';
// Vue.use(Empty);
import { List } from 'vant';
Vue.use(List);
import { Popup } from 'vant';
Vue.use(Popup);
import { Tab, Tabs } from 'vant';
Vue.use(Tab);
Vue.use(Tabs);
import { DropdownMenu, DropdownItem } from 'vant';
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
import { Icon } from 'vant';
Vue.use(Icon);
import { Search } from 'vant';
Vue.use(Search);
import { IndexBar, IndexAnchor } from 'vant';
Vue.use(IndexBar);
Vue.use(IndexAnchor);
import { Field } from 'vant';
Vue.use(Field);
import { Area } from 'vant';
Vue.use(Area);
import { Swipe, SwipeItem } from 'vant';
Vue.use(Swipe);
Vue.use(SwipeItem);
import { Toast,Dialog } from 'vant';
Vue.use(Toast)
Vue.use(Dialog)
import { Form } from 'vant';
Vue.use(Form);
import { Overlay } from 'vant';
Vue.use(Overlay);
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
import { Picker } from 'vant';
Vue.use(Picker);
import { Switch } from 'vant';
Vue.use(Switch);
import { Uploader } from 'vant';
Vue.use(Uploader);
import { Badge } from 'vant';
Vue.use(Badge);
// import { Divider } from 'vant';
// Vue.use(Divider);

const Home = ()=> import('@/components/Home')
const Main = ()=> import('@/components/Main')
const FindOut = ()=> import('@/components/FindOut')
const Connections = ()=> import('@/components/Connections')
const CompanyList = ()=> import('@/components/CompanyList')
const Information = ()=> import('@/components/Information')
const Inspiration = ()=> import('@/components/Inspiration')
const StarMix = ()=> import('@/components/StarMix')
const ActivityLive = ()=> import('@/components/ActivityLive')
const ActivityCourse = ()=> import('@/components/ActivityCourse')
const ActivityLine = ()=> import('@/components/ActivityLine')
const Activity = ()=> import('@/components/Activity')

const Authority = ()=> import('@/components/Authority')
const Personal = ()=> import('@/components/Personal')
const Message = ()=> import('@/components/Message')




const Question = ()=> import('@/components/find//Question')
const Introduction = ()=> import('@/components/find/Introduction')
const CompanyDetail = ()=> import('@/components/find/CompanyDetail')
const QuestionDetail = ()=> import('@/components/find/QuestionDetail')
const SearchFor = ()=> import('@/components/find/SearchFor')
const ArticlesList = ()=> import('@/components/find/ArticlesList')
const ArticleDetail = ()=> import('@/components/find/ArticleDetail')
const MarketDetail = ()=> import('@/components/find/MarketDetail')


const findConnections = ()=> import('@/components/find/SearchFor')
const QuestionAnswer = ()=> import('@/components/find/QuestionAnswer')
const QuestionAnswerEdit = ()=> import('@/components/find/QuestionAnswerEdit')





const LiveList = ()=> import('@/components/active/LiveList')
const LiveDetail = ()=> import('@/components/active/LiveDetail')
const CoursesDetail = ()=> import('@/components/active/CoursesDetail')
const CoursesDetailLink = ()=> import('@/components/active/CoursesDetailLink')


const ProgrammeDetail = ()=> import('@/components/active/ProgrammeDetail')
const MemberProduct = ()=> import('@/components/active/MemberProduct')
const Invitational = ()=> import('@/components/active/Invitational')
const InviteList = ()=> import('@/components/active/InviteList')


const SignUpEnd = ()=> import('@/components/active/SignUpEnd')
const Forms = ()=> import('@/components/active/Forms')
const CertifiedMember = ()=> import('@/components/active/CertifiedMember')

const MyCollection = ()=> import('@/components/mine/MyCollection')
const SayHelloTo = ()=> import('@/components/mine/SayHelloTo')
const MyQuesAnswer = ()=> import('@/components/mine/MyQuesAnswer')
const EditMessage = ()=> import('@/components/mine/EditMessage')
const OrderForm = ()=> import('@/components/mine/OrderForm')
const Setting = ()=> import('@/components/mine/Setting')


const EditCircle = ()=> import('@/components/circle/EditCircle')
const Market = ()=> import('@/components/circle/Market')

const MessageDetail = ()=> import('@/components/circle/MessageDetail')



// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

Vue.use(Router)
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      redirect:'/Home'
    },
    {
      path: '/CoursesDetailLink',
      name: 'CoursesDetailLink',
      component: CoursesDetailLink,
      meta: {
        title: '课程详情'
      }
    },
    {
      path: '/EditCircle',
      name: 'EditCircle',
      component: EditCircle,
      meta: {
        title: '编辑发布'
      }
    },
    {
      path: '/Market',
      name: 'Market',
      component: Market,
      meta: {
        title: '编辑发布'
      }
    },
    {
      path: '/MessageDetail',
      name: 'MessageDetail',
      component: MessageDetail,
      meta: {
        title: '消息详情'
      }
    },
    {
      path: '/MarketDetail',
      name: 'MarketDetail',
      component: MarketDetail,
      meta: {
        title: '集市详情'
      }
    },
    
    {
      path: '/QuestionAnswer',
      name: 'QuestionAnswer',
      component: QuestionAnswer,
      meta: {
        title: '回答问题'
      }
    },
    {
      path: '/QuestionAnswerEdit',
      name: 'QuestionAnswerEdit',
      component: QuestionAnswerEdit,
      meta: {
        title: '回答问题'
      }
    },
    {
      path: '/CertifiedMember',
      name: 'CertifiedMember',
      component: CertifiedMember,
      meta: {
        title: '认证会员'
      }
    },
    {
      path: '/Main',
      name: 'Main',
      component: Main,
      children:[
        {
          path: '/Personal',
          name: 'Personal',
          component: Personal,
          meta: {
            title: '个人中心'
          }
        },
        {
          path: '/Message',
          name: 'Message',
          component: Message,
          meta: {
            title: '消息'
          }
        },
        {
          path: '/Home',
          name: 'Home',
          component: Home,
          meta: {
            title: '保观大咖会'
          }
        },
        {
          path: '/Information',
          name: 'Information',
          component: Information,
          meta: {
            title: '星球'
          }
        },
        {
          path: '/Inspiration',
          name: 'Inspiration',
          component: Inspiration,
          meta: {
            title: '想法'
          }
        },
        {
          path: '/StarMix',
          name: 'StarMix',
          component: StarMix,
          meta: {
            title: '星球'
          }
        },
        {
          path: '/FindOut',
          name: 'FindOut',
          component: FindOut,
          meta: {
            title: '社群'
          }
        },
        {
          path: '/Connections',
          name: 'Connections',
          component: Connections,
          meta: {
            title: '社群'
          }
        },
        {
          path: '/CompanyList',
          name: 'CompanyList',
          component: CompanyList,
          meta: {
            title: '社群'
          }
        },
        {
          path: '/ActivityLive',
          name: 'ActivityLive',
          component: ActivityLive,
          meta: {
            title: '直播'
          }
        },
        {
          path: '/Activity',
          name: 'Activity',
          component: Activity,
          meta: {
            title: '活动'
          }
        },
        {
          path: '/ActivityLine',
          name: 'ActivityLine',
          component: ActivityLine,
          meta: {
            title: '社群'
          }
        },
        {
          path: '/ActivityCourse',
          name: 'ActivityCourse',
          component: ActivityCourse,
          meta: {
            title: '星球'
          }
        },
        
      ]
    },
    {
      path: '/findConnections',
      name: 'findConnections',
      component: findConnections,
      meta: {
        title: '邀请大咖'
      }
    },
    {
      path: '/MyCollection',
      name: 'MyCollection',
      component: MyCollection,
      meta: {
        title: '我的收藏'
      }
    },
    {
      path: '/Setting',
      name: 'Setting',
      component: Setting,
      meta: {
        title: '设置'
      }
    },
    {
      path: '/OrderForm',
      name: 'OrderForm',
      component: OrderForm,
      meta: {
        title: '我的订单'
      }
    },
    {
      path: '/EditMessage',
      name: 'EditMessage',
      component: EditMessage,
      meta: {
        title: '编辑信息'
      }
    },
    {
      path: '/MyQuesAnswer',
      name: 'MyQuesAnswer',
      component: MyQuesAnswer,
      meta: {
        title: '我的问答'
      }
    },
    {
      path: '/SayHelloTo',
      name: 'SayHelloTo',
      component: SayHelloTo,
      meta: {
        title: '我的消息'
      }
    },
    {
      path: '/Invitational',
      name: 'Invitational',
      component: Invitational,
      meta: {
        title: '邀请好友'
      }
    },
    {
      path: '/InviteList',
      name: 'InviteList',
      component: InviteList,
      meta: {
        title: '邀请记录'
      }
    },
    {
      path: '/MemberProduct',
      name: 'MemberProduct',
      component: MemberProduct,
      meta: {
        title: '会员购买'
      }
    },
    {
      path: '/LiveList',
      name: 'LiveList',
      component: LiveList,
      meta: {
        title: '直播回放列表'
      }
    },
    {
      path: '/Forms',
      name: 'Forms',
      component: Forms,
      meta: {
        title: '报名'
      }
    },
    {
      path: '/LiveDetail',
      name: 'LiveDetail',
      component: LiveDetail,
      meta: {
        title: '直播详情'
      }
    },
    {
      path: '/CoursesDetail',
      name: 'CoursesDetail',
      component: CoursesDetail,
      meta: {
        title: '课程详情'
      }
    },
    {
      path: '/ProgrammeDetail',
      name: 'ProgrammeDetail',
      component: ProgrammeDetail,
      meta: {
        title: '活动详情'
      }
    },
    
    {
      path: '/CompanyDetail',
      name: 'CompanyDetail',
      component: CompanyDetail,
      meta: {
        title: '公司详情'
      }
    },
    {
      path: '/SearchFor',
      name: 'SearchFor',
      component: SearchFor,
      meta: {
        title: '搜索'
      }
    },
    {
      path: '/Question',
      name: 'Question',
      component: Question,
      meta: {
        title: '提问'
      }
    },
    {
      path: '/QuestionDetail',
      name: 'QuestionDetail',
      component: QuestionDetail,
      meta: {
        title: '问题详情'
      }
    },
    {
      path: '/Introduction',
      name: 'Introduction',
      component: Introduction,
      meta: {
        title: '大咖介绍'
      }
    },
    {
      path: '/Authority',
      name: 'Authority',
      component: Authority,
      meta: {
        title: '授权中'
      }
    },
    {
      path: '/SignUpEnd',
      name: 'SignUpEnd',
      component: SignUpEnd,
      meta: {
        title: '活动订阅'
      }
    },
    {
      path: '/ArticlesList',
      name: 'ArticlesList',
      component: ArticlesList,
      meta: {
        title: '保观文章'
      }
    },
    {
      path: '/ArticleDetail',
      name: 'ArticleDetail',
      component: ArticleDetail,
      meta: {
        title: '文章详情'
      }
    },
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  //  第一次进入项目
  // let baseInfoDkh = storage.get("baseInfoDkh")
  if(!Global.getCookie('tokenDkhNww')){  //判断是否有token
    if(to.path == "/MemberProduct"){
      // console.log(to,'to=====')
      localStorage.setItem("beforeLoginUrl1", to.fullPath)// 保存用户进入的url
    }else if ( to.path != "/Authority") {
      localStorage.setItem("beforeLoginUrl1", to.fullPath)// 保存用户进入的url
      // if(to.query){
      //   window.localStorage.setItem("queryItem", JSON.stringify(to.query))
      // }
      next("/Authority")
      return false;
    }
    // 有token也要存储一下to.fullPath 防止token失效的情况下授权跳转找不到前一个页面
  }
  next()
})

// 页面上划
router.afterEach(() => {
  window.scrollTo(0,0)
})

export default router
