<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: SimHei,Microsoft YaHei,PingFang TC,Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  font-size: 0.14rem;
  max-width: 750px;
  margin: 0 auto;
  background: #16171D;
  min-height: 100vh;
}
html{
  overflow: auto;
}
body,p,img,div,html{
  margin: 0;
  padding: 0;
}
img{
  display: block;
}
#app .van-search .van-cell{
  padding: 4px 8px 4px 0;
}
#app .van-popup{
  z-index: 5 !important;
  margin: auto;
}
#app .van-overlay{
  z-index: 4 !important;
}
#Home .van-swipe{
  border-radius: 0.05rem;
  overflow: hidden;
}
#Connections .van-index-anchor{
  background: #16171D;
}
#Question .van-cell，#Market .van-cell{
  padding: 0.1rem 0.15rem;
}
#CompanyList .van-picker__mask,#EditMessage .van-picker__mask{
  background-image: linear-gradient(180deg, rgb(35 37 46 / 90%), rgba(35,37, 46, 0.4)), linear-gradient(0deg, rgba(35,37, 46, 0.9), rgba(35,37, 46, 0.4));
}
#CompanyList .van-ellipsis,#EditMessage .van-ellipsis{
  color: #F6F7FD;
  font-size: 0.14rem;
}
 #app .van-search {
   padding: 0;
 }
 #app .van-index-bar__sidebar{
   display: none;
 }
#Activity .van-popup--top{
  top: 1.4rem;
}
#Activity .van-tab__pane{
  z-index: 10;
  background: #23252E;
  max-width: 750px;
}
#FindOut .van-popup--top{
  top: 0.48rem;
}
#Information .van-popup--top{
  top: 1.4rem;
}
#Inspiration .van-popup--top{
  top: 0.95rem;
}
#MyCollection .van-popup--top{
  top: 0.43rem;
}
#Connections .van-popup--top{
   top: 1.45rem;
}
#CompanyList .van-popup--top{
   top: 1.4rem;
 }
body .van-toast{
   color: #F6F7FD;
 }
body  .van-toast__icon{
   font-size: 0.2rem;
 }
 #LiveDetail .personalInfo >div .third img{
    background: red;
    max-width: 100%;
  }
#Activity .van-tab,#SayHelloTo .van-tab,#MyQuesAnswer .van-tab,#OrderForm .van-tab,#Introduction .van-tab{
  flex: none;
  margin-right: 0.2rem;
  padding: 0;
}
#Activity .van-tabs--line .van-tabs__wrap,#SayHelloTo .van-tabs--line .van-tabs__wrap,#MyQuesAnswer .van-tabs--line .van-tabs__wrap,
#OrderForm .van-tabs--line .van-tabs__wrap{
  height: 0.5rem;
  position: fixed;
  width: 100%;
  max-width: 750px;
  top: 0;
  z-index: 10;
}
#Introduction .van-tabs--line .van-tabs__wrap{
  height: 0.5rem;
  width: 100%;
  z-index: 10;
}
#Activity .van-tabs__content,#SayHelloTo .van-tabs__content,#MyQuesAnswer .van-tabs__content,#OrderForm .van-tabs__content{
  padding-top: 0.5rem;
}
#Activity .van-tabs__nav--line,#SayHelloTo .van-tabs__nav--line,#MyQuesAnswer .van-tabs__nav--line,#OrderForm .van-tabs__nav--line,#Introduction .van-tabs__nav--line{
  padding:0 0.15rem 5px ;
}
#Introduction .van-tab{
  padding-left: 0;
}
#Introduction .van-tabs__line{
  left: -4px;
  bottom: 10px;
}
#Activity .topTitle{
  padding: 0 0.15rem;
}
#Setting .van-switch__node{
  background: #FFFFFF;
}
#Setting .van-switch {
  background: rgba(120, 120, 128, 0.16);
}
#Forms .van-cell,#EditMessage .van-cell{
  padding: 0.15rem;
  background: #16171D;
}
#Forms .van-cell::after,#EditMessage .van-cell::after {
  left: 0;
  border-bottom: .1px solid #5C5D6E61;
}
#FindOut .editSure .van-cell::after,#QuestionDetail .editSure .van-cell::after{
  border-bottom:none
}
#Forms .van-field--error .van-field__control, .van-field--error .van-field__control::placeholder,#Forms .van-field__error-message,
#EditMessage .van-field--error .van-field__control, .van-field--error .van-field__control::placeholder,#EditMessage .van-field__error-message{
  color: #d73b3b;
  -webkit-text-fill-color:#d73b3b
}
#EditMessage .from .van-field__control{
  text-align: right;
}
#EditMessage .van-cell__title,#FindOut .van-cell__title{
  color: #ECEDF7;
}
#EditMessage .van-field__control,#FindOut .van-field__control{
  color: #ECEDF7;
}
#EditMessage .edit .van-cell__value,#FindOut .edit .van-cell__value,#Inspiration .edit .van-cell__value,#StarMix .edit .van-cell__value{
  background: #3D404F;
  border-radius: 0.05rem;
  padding: 0.12rem;
}
#Forms .van-field--error .van-field__control::-webkit-input-placeholder,#EditMessage .van-field--error .van-field__control::-webkit-input-placeholder{
  color: #d73b3b;
}
#Forms .van-field__control::-webkit-input-placeholder,#EditMessage .van-field__control::-webkit-input-placeholder{
  color: #9091A3;
}
.van-dialog,.van-button__content{
  background:#fff !important;
}
#Forms .van-cell--required::before{
  color: #ee0a24;
}
#Forms .van-field__label{
  color:#F6F7FD
}
#EditCircle .pre{
	padding: 10px 0;
}

#EditCircle .van-icon-photograph::before {
  content: '\F0A2';
}
#EditCircle .van-uploader__preview,#EditCircle .van-uploader__upload{
  width: 33%;
  position: relative;
  padding-bottom: 33%;
  height: 0;
  overflow: hidden;
  margin: 0;
}
#EditCircle .van-uploader__upload-icon {
  position: absolute;
  bottom: 0;
  top: 0;
  margin: auto;
  height: 24px;
}
#EditCircle .van-uploader__preview-image{
  position: absolute;
  width: 100%;
  height: 100%;
}
#EditCircle .van-uploader{
  width: 100%;
}
#EditCircle .van-uploader__wrapper >div:nth-child(3n+2){
  /* justify-content: space-between; */
	margin: 0 0.5%;
}
#EditCircle .van-uploader__upload:active{
  background-color: #3D404F;
}
#EditCircle .van-uploader__preview-delete-icon{
  color: white;
}
#Message .van-badge{
  color:#ECEDF7
}
.blackFix,#FindOut .posiFixed,#CompanyList .posFix,.botFixed{
  max-width: 750px;
}
</style>
