import Vue from 'vue'
import App from './App.vue' 
import axios from 'axios'
import router from './router'
import wx from 'jweixin-module'
import Global from '@/assets/js/global'
import base64 from 'base-64'

// import VConsole from 'vconsole';

// const vConsole = new VConsole();

// console.log(vConsole)

Vue.prototype.Global = Global
Vue.config.productionTip = false
Vue.prototype.axios = axios
Vue.prototype.wx = wx
Vue.prototype.base64 = base64

import moment from 'moment'//导入文件
Vue.prototype.$moment = moment;//赋值使用
moment.locale('zh-cn');//需要汉化

// import storage from 'good-storage'
// Vue.prototype.Storage = storage

  // await getServerConfig()
  axios.defaults.baseURL = Global.domain;
  axios.interceptors.request.use( (config) => {
    config.headers.accept = 'application/json';
    config.headers.Authorization = `Bearer ${Global.getCookie('tokenDkhNww')}` 
    // console.log(config)
    if(config.url.indexOf('/activity/checkUserApplyStatus/') == -1){
      // 在发送请求之前做些什么
      vueObj.$toast({
        type: 'loading',
        duration: 0, // 持续展示 toast
        forbidClick: true,
        overlay: true
      })
    }
    return config;
  })

  axios.interceptors.response.use( (response) => {
    // console.log(response.data.code)
    let resCode = response.data.code
    vueObj.$toast.clear()
    switch (resCode){
      case 40001:
        vueObj.$router.push('/Authority')
        break
      default:
        return response
    }
  }, function (error) {
    return Promise.reject(error);
  })

  const vueObj = new Vue({
    router,
    // store,
    render: h => h(App),
  }).$mount('#app')
  
  export default vueObj

